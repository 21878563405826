<template>
  <v-dialog
    v-model="modal"
    @close="resetForm"
    :max-width="maxWidth"
    :scrollable="scrollable"
    :eager="eager"
    persistent
  >
    <v-form 
      ref="form"
      @submit.prevent="submitForm"
    >
      <v-card
        @drop.prevent="$emit('dropped', $event)"
        @dragover.prevent="$emit('draggedover', $event)"
        @dragenter.prevent="$emit('draggedenter', $event)"
        @dragleave.prevent="$emit('draggedleave', $event)"
        @dragend.prevent="$emit('draggedend', $event)"
        class="form-modal"
      >
        <v-img
          v-if="cardCover"
          :src="cardCover"
          height="250"
          class="cover-bg"
          gradient="to bottom, rgba(0, 0, 0, 0.8) 0%, transparent 82px"
          justify="center"
          contain
        ><v-card-title>
            <slot name="title">
              <span class="headline white--text">{{ title }}</span>
            </slot>
          </v-card-title>
        </v-img>

        <v-card-title v-else>
          <slot name="title">
            <span class="headline">{{ title }}</span>
          </slot>
        </v-card-title>

        <v-card-text>

          <slot name="content"></slot>

        </v-card-text>

        <div v-if="loading" class="px-4 py-3">
          <v-progress-linear
            indeterminate
            color="primary"
            rounded
          ></v-progress-linear>
        </div >

        <v-card-actions>

          <slot name="bottom-actions"></slot>

          <v-spacer></v-spacer>
          
          <v-btn
            color="grey"
            text
            @click="modal = false"
          >
            {{ $t('close') }}
          </v-btn>

          <v-btn
            v-if="!deleteModal && !hideSaveButton"
            :disabled="saveButtonDisabled"
            :loading="submitting"
            color="success"
            text
            type="submit"
          >
            {{ $t(submitButtonText || 'save') }}
          </v-btn>

          <v-btn
            v-if="deleteModal"
            :loading="submitting"
            color="error"
            text
            type="submit"
          >
          {{ $t(submitButtonText || 'remove') }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>

<style>
  .form-modal .v-card__text {
    padding-top: 10px;
  }
  
  .form-modal .v-card__text .col.col-12 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .form-modal .v-input--switch {
    margin: 0;
    padding: 0;
  }

  .form-modal .v-treeview-node__root {
    height: 48px;
  }

  .cover-bg {
    background-color: #DADCE0;
  }
</style>

<script>

export default {
  name: 'DefaultModal',

  data: () => ({
    modal: false,
    submitting: false,
  }),

  watch: {
    modal(from) {
      if (from == false) {
        this.resetForm()
        this.$emit('modal-closed')
      }
    }
  },

  props: {
    title: {
      type: String,
    },

    deleteModal: {
      type: Boolean,
      default: false
    },

    submitButtonText: {
      type: String,
    },

    hideSaveButton: {
      type: Boolean,
      default: false
    },

    saveButtonDisabled: {
      type: Boolean,
      default: false
    },

    scrollable: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    maxWidth: {
      type: String,
      default: "600px"
    },

    eager: {
      type: Boolean,
      default: false
    },

    cardCover: {
      type: String,
    },
  },

  methods: {
    toggleModal() {
      this.modal = !this.modal
    },

    openModal() {
      this.modal = true
    },

    closeModal() {
      this.modal = false
    },

    submitForm(e) {
      if (!this.$refs.form.validate()) {
        return
      }

      this.submitting = true

      this.$emit('submit', new FormData(e.target))
    },

    resetForm() {
      this.submitting = false

      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },

    setIsSubmitting(value) {
      this.submitting = value
    },
  }
}
</script>
